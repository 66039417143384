export default {
  en: {
    about: "About",
    artifacts_listed_here: "The artifacts listed here are available on {link}.",
    done: "Done!",
    download: "Download",
    drums: "Drums",
    error: "Error! Please try again.",
    guitar_bass: "Guitar / Bass",
    guitarix_browser_not_supported: "Guitarix preview is not supported by your browser. Please use Firefox or Chrome",
    header_guitarix_artifacts: "Preview Guitarix artifacts",
    header_midi: "Preview soundfont artifacts",
    icons_credits: "Icons Credits",
    link_to_github: "Source code is available at {link}.",
    loading: "Loading",
    loading_guitarix_artifacts: "Loading guitarix artifacts...",
    loading_soundfont_artifacts: "Loading soundfont artifacts...",
    midi_controller: "MIDI Controller",
    musical_artifacts: "Musical Artifacts",
    no_artifacts: "No artifacts selected.",
    processed_files: "Processed recordings",
    processing_record: "Your recording is being processed",
    project_description: "With this tool you can preview Guitarix and Soundfonts files from musical-artifacts.com directly in the browser, using your musical instrument as input.",
    queue_position: "Your recording is #{position_in_queue} in queue",
    record_start: "Start recording",
    record_stop: "Stop recording",
    record_please_msg: "« Click on the button to start recording.",
    recording_title: "Your recording",
    select_another_instrument: "Select another instrument",
    select_artifact: "Artifact",
    select_instrument: "Select your instrument",
    select_midi_instrument: "Instrument",
    select_number_keys: "Number of keys",
    select_preset: "Preset",
    start_processing: "Start processing",
    uploading_recording: "Uploading your recording...",
    view_on_ma: "View on Musical Artifacts"
  },
  pt: {
    about: "Sobre",
    artifacts_listed_here: "Os artefatos listados aqui estão disponíveis no {link}.",
    done: "Pronto!",
    download: "Baixar",
    drums: "Bateria",
    error: "Ocorreu um erro! Por favor, tente novamente.",
    guitar_bass: "Guitarra / Baixo",
    guitarix_browser_not_supported: "A pré-visualização de artefatos para Guitarix não é suportada pelo seu navegador. Por favor, utilize Firefox ou Chrome.",
    header_guitarix_artifacts: "Artefatos para Guitarix",
    header_midi: "Artefatos de Soundfonts",
    icons_credits: "Créditos dos Ícones",
    link_to_github: "O código fonte está disponível no {link}.",
    loading: "Carregando",
    loading_guitarix_artifacts: "Carregando artefatos para guitarix...",
    loading_soundfont_artifacts: "Carregando lista de soundfonts...",
    midi_controller: "Controlador MIDI",
    musical_artifacts: "Musical Artifacts",
    no_artifacts: "Nenhum artefato selecionado.",
    processed_files: "Gravações processadas",
    processing_record: "Sua gravação está sendo processada",
    project_description: "Usando esta ferramenta você pode pré-visualizar artefatos musicais do site musical-artifacts.com diretamente no seu navegador, usando seu instrumento musical.",
    queue_position: "Sua gravação é a #{position_in_queue} da fila",
    record_start: "Iniciar gravação",
    record_stop: "Encerrar gravação",
    record_please_msg: "« Clique no botão para iniciar a gravação.",
    recording_title: "Sua gravação",
    select_another_instrument: "Selecionar outra instrumento",
    select_artifact: "Artefato",
    select_instrument: "Selecione seu instrumento",
    select_midi_instrument: "Instrumento",
    select_number_keys: "Número de teclas",
    select_preset: "Preset",
    start_processing: "Processar",
    uploading_recording: "Enviando sua gravação...",
    view_on_ma: "Ver no Musical Artifacts",
  }
};
