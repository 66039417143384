import React, { Component } from 'react';
import './styles/css/SelectInstrument.css';

class Drums extends Component {
  render() {
    return (
      <h4>Not yet implemented</h4>
    );
  }
}

export default Drums;
